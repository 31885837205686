var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"petbrick-65"},[_c('v-header',[_c('div',{staticClass:"item",on:{"click":function($event){return _vm.onDownload(
          _vm.oss +
            'pdf/PETBRICK65UserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf'
        )}}},[_c('i',{staticClass:"iconfont icon-download"}),_vm._v(" "+_vm._s(_vm.$t("userManual"))+" ")]),(false)?_c('div',{staticClass:"item",on:{"click":function($event){return _vm.onOpen('https://www.angrymiao.com/verify/petbrick-65/')}}},[_vm._v(" "+_vm._s(_vm.$t("verify"))+" ")]):_vm._e(),_c('div',{staticClass:"item",staticStyle:{"font-style":"italic","font-weight":"bold"},on:{"click":function($event){return _vm.onOpen('https://diy.angrymiao.com/keyboard/petbrick-65/')}}},[_vm._v(" > DIY ")]),_c('div',{staticClass:"item",staticStyle:{"font-style":"italic","font-weight":"bold"},on:{"click":function($event){return _vm.onOpen('https://www.angrymiao.com/am-master/')}}},[_vm._v(" > AM MASTER ")]),_c('div',{staticClass:"buy",on:{"click":function($event){return _vm.onOpen(
          _vm.isCN
            ? 'https://m.angrymiao.com/products/1466'
            : 'https://store.dry---studio.com/products/dry-studio-petbrick-65'
        )}}},[_vm._v(" "+_vm._s(_vm.$t("buy"))+" ")])]),(_vm.isCN)?[_c('article',[_c('img',{staticClass:"fall",attrs:{"src":_vm.oss + 'petbrick_65/cn_01.jpg',"alt":""}}),_c('video',{staticClass:"fall video",attrs:{"src":_vm.oss + 'petbrick_65/video_cn_02.mp4',"controls":""}}),_c('img',{staticClass:"fall",attrs:{"src":_vm.oss + 'petbrick_65/cn_03.jpg',"alt":""}}),_c('video',{staticClass:"fall video",attrs:{"src":_vm.oss + 'petbrick_65/video_cn_04.mp4',"controls":""}}),_c('img',{staticClass:"fall",attrs:{"src":_vm.oss + 'petbrick_65/cn_05.jpg',"alt":""}}),_c('img',{staticClass:"fall",attrs:{"src":_vm.oss + 'petbrick_65/cn_06.jpg',"alt":""}})])]:[_c('article',[_c('img',{staticClass:"fall",attrs:{"src":_vm.oss + 'petbrick_65/en_01.jpg',"alt":""}}),_c('img',{staticClass:"fall",attrs:{"src":_vm.oss + 'petbrick_65/en_02.jpg',"alt":""}}),_c('img',{staticClass:"fall",attrs:{"src":_vm.oss + 'petbrick_65/en_03.gif',"alt":""}}),_c('img',{staticClass:"fall",attrs:{"src":_vm.oss + 'petbrick_65/en_04.jpg',"alt":""}}),_c('img',{staticClass:"fall",attrs:{"src":_vm.oss + 'petbrick_65/en_05.jpg',"alt":""}}),_c('img',{staticClass:"fall",attrs:{"src":_vm.oss + 'petbrick_65/en_06.jpg',"alt":""}})])],_c('v-footer')],2)}
var staticRenderFns = []

export { render, staticRenderFns }